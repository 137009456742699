import React from 'react';
import { css } from '@emotion/react';

import Layout from '../components/Layout';

const about = {
  intro:
    `As a Lead Software Engineer at DocuSign, I work with a team of talented developers to create and maintain web applications that enable millions of users to sign, send, and manage documents online. I use React, TypeScript, and Redux.js to build responsive, performant, and accessible user interfaces that integrate with various APIs and services.

    I have over five years of experience in software engineering, with a focus on front end development. I hold a Front End Development Certification from freeCodeCamp, where I learned the fundamentals of computer software engineering and web development. I am passionate about exploring new technologies, solving complex problems, and delivering high-quality products that meet the needs and expectations of our customers. `,
  current: `Likely riding a bike, making coffee, or chasing my Son.`,
  likes:
    'I like dogs, coffee, and over engineered equipment. I am an avid hobbyist, these hobbies include but are not limited to building keyboards, playing golf, auto racing, bikes, photography, travel, home automation, and film/tv critique.',
  experience: [
    'Senior Software Engineer - DocuSign',
    'Senior JavaScript Engineer - Third and Grove',
    'Front End Engineer - Zesty.io Platform',
    'Software Engineer - Applied Statistics and Management',
  ],
  proficient: [
    'HTML',
    'CSS',
    'JavaScript',
    'React',
    'JSON',
    'GIT',
    'Redux',
    'Webpack',
    'Nx',
    'Gatsby',
    'Nextjs',
    'GraphQL',
    'TypeScript',
  ],
};
const Index = () => (
  <Layout
    title={
      <span
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <p>Grant Glidewell</p>
        <img
          css={css`
            width: 100px;
            height: auto;
            min-height: 100px;
            border-radius: 50%;
          `}
          src='https://avatars.githubusercontent.com/u/26661451'
          alt='Grant, probably smiling'
        />
      </span>
    }
  >
    <section>
      <h3>Intro</h3>
      <p>{about.intro}</p>
    </section>
    <section>
      <h3>Likes</h3>
      <p>{about.likes}</p>
    </section>
    <section>
      <h3>Current</h3>
      <p>{about.current}</p>
    </section>
    <section>
      <h3>Experience</h3>
      <ul>
        {about.experience.map(exp => (
          <li key={exp}>{exp}</li>
        ))}
      </ul>
    </section>

    <section>
      <h3>Proficient</h3>
      {about.proficient.map((exp, i) => (
        <>
          <span key={exp}>{exp}</span>
          {i !== about.proficient.length - 1 && ' ° '}
        </>
      ))}
    </section>
  </Layout>
);

export default Index;
